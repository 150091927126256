<template>
  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
  ></v-progress-linear>

  <v-container v-else class="py-12">
    <div class="d-flex align-center">
      <h1 class="text-h4">ENF</h1>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            outlined
            label
            :color="getStatusColor(instance)"
            v-bind="attrs"
            v-on="on"
            class="ml-4"
          >
            {{ instance.status }}
          </v-chip>
        </template>

        <span>
          Du {{ instance.start_date | formatDate }}
          au {{ instance.end_date | formatDate }}
        </span>
      </v-tooltip>
    </div>

    <h2
      class="text-subtitle-1"
      v-if="cursus"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            v-on="on"
            v-bind="attrs"
          >
            {{ getDeptIcon(cursus.dept_acronym) }}
          </v-icon>
        </template>

        <span>
          {{ cursus.dept_acronym }}
        </span>
      </v-tooltip>

      {{ cursus.name }}, {{ cursus.est_name }}
    </h2>

    <v-breadcrumbs
      :items="navItems"
      class="px-0 py-2"
    >
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <router-view
      :instanceId="instanceId"
      :instanceStatus="instance.status"
      :instanceType="instance.type"
      class="mt-6"
      :cursusDept="cursus ? cursus.dept_acronym : null"
    ></router-view>
  </v-container>
</template>

<script>
import { getDeptIcon, getStatusColor } from '@/mixins/methodsMixin';

export default {
  name: 'AdminEnf',

  props: {
    instanceId: String,
  },

  mixins: [getStatusColor, getDeptIcon],

  data() {
    return {
      instance: null,
      cursus: null,
      isLoading: false,
    };
  },

  async created() {
    this.isLoading = true;
    await this.getInstance();
    if (Object.hasOwnProperty.call(this.$route.params, 'cursusId')) {
      await this.getCursus(this.$route.params.cursusId);
    }
    this.isLoading = false;
  },

  computed: {
    navItems() {
      const items = [
        {
          text: 'Instances',
          disabled: false,
          to: '/admin/instances',
        },
        {
          text: 'Cursus',
          disabled: false,
          to: `/admin/enf/${this.instanceId}`,
          exact: true,
        },
      ];

      if (this.$route.name === 'AdminEnfRegistrations' || this.$route.name === 'AdminEnfRegistrationDetails') {
        items.push({
          text: 'Inscriptions',
          disabled: false,
          to: `/admin/enf/${this.instanceId}/cursus/${this.$route.params.cursusId}`,
          exact: true,
        });
      }
      return items;
    },
  },

  methods: {
    async getInstance() {
      await this.$store.dispatch('getAccessToken');
      try {
        const response = await this.$http.get(`admin/instance-get-by-id.php?instance_id=${this.instanceId}&type=ENF`);
        this.instance = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getCursus(id) {
      await this.$store.dispatch('getAccessToken');
      try {
        const response = await this.$http.get(`admin/cursus-get-by-id.php?cursus_id=${id}&type=ENF`);
        this.cursus = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },

  watch: {
    async $route(to) {
      if (Object.hasOwnProperty.call(to.params, 'cursusId')) {
        this.isLoading = true;
        await this.getCursus(to.params.cursusId);
        this.isLoading = false;
      } else {
        this.cursus = null;
      }
    },
  },
};
</script>
